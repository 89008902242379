import React from "react"
import Layout from "../components/common/layout/layout"
import Navigation from "../components/common/navigation/navigation"
import { Container } from "../components/global"
import SEO from "../components/common/layout/seo"
import TitleHeader from "../components/sections/titleHeader"
import Footer from "../components/sections/footer"

const ImprintPage = () => {
  return (
    <Layout>
      <SEO title="Imprint" />
      <Navigation />
      <TitleHeader>Imprint</TitleHeader>
      <Container>
        <h2>Address</h2>
        <p>Autote</p>
        <p>Langhansstraße 96</p>
        <p>13086 berlin</p>
        <p>Deutschland</p>
        <p>Phone: +49 17645789019</p>
        <p>Email: tigguy@gmail.com</p>
        <h2>Managing Director</h2>
        <p>Tim Gutsche</p>
        <h2>Liability Note</h2>
        <p>
          Despite taking every care to check the content, we ddo not accept
          liability for the content of external links. The operators of sites we
          link to are solely liable for their content
        </p>
      </Container>
      <Footer />
    </Layout>
  )
}

export default ImprintPage
